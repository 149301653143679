<template>
  <div class="content-layout">
    <div class="property-operations">

      <div class="data-filter">
        <a-form layout="inline">
          <a-form-item style="margin: 0;">
          <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
          <a-form-item >
            <a-input allowClear placeholder="请输入" v-model="searchKeyword" class="search-keyword"
              @keyup.enter.native="doSearch"></a-input>
          </a-form-item>

        <a-form-item>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button></a-form-item> </a-form>
      </div>
      <div>
        <a-button v-if="$hasPermissions(['rcs:product_configuration:create'])" type="primary" class="add-btn"
          @click="handleCreator()">新增</a-button>
      </div>
    </div>
    <a-table size="middle" :pagination="false" :columns="this.$constRepository.productConfiguration.columns"
      :data-source="tableDate" class="property-table" rowKey="id">
      <template slot="description" slot-scope="scope">{{
        scope.defaultMessage ? scope.defaultMessage : '暂无'
        }}</template>
      <!-- <template slot="createdTime" slot-scope="scope">{{
        scope | parseTime | orNA
        }}</template> -->
      <template slot="lastModificationTime" slot-scope="scope">{{
        scope | parseTime | orNA
        }}</template>
      <span slot="action" slot-scope="scope">
        <a-button v-if="$hasPermissions(['rcs:product_configuration:update'])" class="green-btn" ghost
          @click="jumptoEdit(scope)">编辑</a-button>
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:product_configuration:get'])" class="green-btn" ghost
          @click="jumptoDetail(scope)">详情</a-button>
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:product_configuration:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除产品配置?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination :current="pageInfo.page" :show-total="(total) => `共 ${pageInfo.totalElements} 条`" show-quick-jumper
        show-size-changer :total="pageInfo.totalElements" @showSizeChange="onShowSizeChange" @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']" />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductConfigurationManagement',
  components: {},
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.productConfiguration.FILTER_OPTION_CONFIGURATION_IDENTIFER.value,
      filterOptions: this.$constRepository.productConfiguration.FILTER_OPTIONS,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    processSearchKeyword() {
      this.$set(this.requestParams, 'searchKey', undefined);
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'identifier', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.productConfiguration.FILTER_OPTION_CONFIGURATION_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.productConfiguration.FILTER_OPTION_CONFIGURATION_IDENTIFER.value:
          this.$set(this.requestParams, 'identifier', this.searchKeyword);
          break;

        default:
          break;
      }
    },
    async listProductConfiguration() {
      const params = {
        ...this.requestParams,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.productConfiguration.getProductConfigurationItems(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listProductConfiguration();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProductConfiguration();
      }
    },
    handleCreator() {
      this.$router.push({ name: 'productConfigurationCreator' });
    },
    jumptoEdit(record) {
      this.$router.push({ name: 'productConfigurationEditor', params: { id: record.id } });
    },
    jumptoDetail(record) {
      this.$router.push({ name: 'productConfigurationDetail', params: { id: record.id } });
    },
    onDelete(record) {
      this.$apiManager.productConfiguration.deleteProductConfigurationItems(record.id).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}

.green-btn {
  color: green;
  border: none;
}

.red-btn {
  color: red;
  border: none;
}

.property-operations {
  display: flex;
  justify-content: space-between;

  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }

  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tip {
      color: #4c4c4c;
    }

    .date-picker-style {
      margin: 0 10px;
    }

    .search-options {
      width: 100px;
    }

    .search-keyword {
      width: 200px;
    }

    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}

.property-table {
  margin-top: 1%;
}

.pagination {
  margin-top: 3%;
}
</style>
