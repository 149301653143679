<template>
  <div>
    <div class="remoteController-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:remote_controller:create'])"
          type="primary"
          class="add-btn"
          @click="jumpToCreate()"
          >新增</a-button
        >
      </div>
      <div class="data-filter" v-if="filterRemoteRelDtos">
        <a-select v-model="deviceTypeSelect" allowClear placeholder="请选择设备类型" style="width: 180px">
          <a-select-option v-for="deviceType in deviceTypeList" :key="deviceType.deviceTypeId">{{
            deviceType.name
          }}</a-select-option>
        </a-select>
        <a-select
          v-if="remoteRelDtosSelected"
          :default-value="remoteRelDtosSelected.value"
          style="width: 120px"
          @change="remoteChange"
        >
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="remote in filterRemoteRelDtos" :key="remote.value">{{
            remote.label
          }}</a-select-option>
        </a-select>
        <a-select
          v-model="brandSelect"
          v-if="remoteRelDtosSelected === this.$constRepository.remoteController.FILTER_OPTION_REMOTE_BRAND"
          allowClear
          placeholder="请选择品牌"
          style="width: 180px"
          showSearch
          labelInValue
        >
          <a-select-option v-for="brand in brandList" :key="`${brand.brandId}-${brand.name}`">{{
            brand.name
          }}</a-select-option>
        </a-select>
        <a-select
          v-model="providerSelect"
          v-if="remoteRelDtosSelected === this.$constRepository.remoteController.FILTER_OPTION_REMOTE_PROVIDER"
          allowClear
          placeholder="请选择服务商"
          style="width: 180px"
          showSearch
          labelInValue
        >
          <a-select-option v-for="provider in providerList" :key="`${provider.serviceProviderId}-${provider.name}`">{{
            provider.name
          }}</a-select-option>
        </a-select>
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="select in filterOptions" :key="select.value" :value="select.value">{{ select.label }}</a-select-option>
        </a-select>
        <a-input
          v-model="searchKeyword"
          class="search-keyword"
          clearable
          placeholder
          style="width: 120px"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch">搜索</a-button>
      </div>
    </div>
    <a-table class="remoteController-table" bordered :data-source="remoteControllerList" rowKey="remoteControllerId" :pagination="false">
      <a-table-column title="码库id" key="remoteControllerId">
        <template slot-scope="scope">
          <div>{{ scope.remoteControllerId }}</div>
        </template>
      </a-table-column>
      <a-table-column title="码库名称" key="name">
        <template slot-scope="scope">
          <div>{{ scope.name | orNA }}</div>
        </template>
      </a-table-column>
      <a-table-column title="频率" key="frequency">
        <template slot-scope="scope">
          <div>{{ scope.frequency | orNA }}</div>
        </template>
      </a-table-column>
      <a-table-column title="备注说明" key="remark">
        <template slot-scope="scope">
          <div>{{ scope.remark | orNA }}</div>
        </template>
      </a-table-column>
      <a-table-column title="服务商绑定" key="spRemoteRelId" width="20%">
        <template slot-scope="scope">
          <div>{{ scope.spRemoteRelDtos | getSpRemoteRels }}</div>
        </template>
      </a-table-column>
      <a-table-column title="品牌设备绑定" key="brandRemoteRelId" width="20%">
        <template slot-scope="scope">
          <div>{{ scope.brandRemoteRelDtos | getBrandRemoteRels }}</div>
        </template>
      </a-table-column>
      <a-table-column key="action" title="操作">
        <template slot-scope="text, record">
          <span>
            <a-button
              v-if="$hasPermissions(['rcs:remote_controller:update'])"
              class="green-btn"
              ghost
              @click="jumptoEdit(record)"
              >编辑</a-button
            >
            <a-divider type="vertical" />
            <a-button v-if="$hasPermissions(['rcs:i18n:list'])" class="green-btn" ghost @click="jumptoI18n(record)"
              >国际化</a-button
            >
            <a-divider type="vertical" />
            <a-button v-if="$hasPermissions(['rcs:remote_controller:delete'])" class="red-btn" ghost>
              <a-popconfirm okText="是" cancelText="否" title="是否删除码库?" @confirm="() => onDelete(record)">
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </a-button>
          </span>
        </template>
      </a-table-column>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';

export default {
  name: 'RemoteControllerList',
  components: {},
  activated() {
    this.loadedPageData();
    this.getBrands();
    this.getProvider();
    this.getDeviceType();
  },
  filters: {
    getBrandRemoteRels(brandRemoteRels) {
      if (!brandRemoteRels) {
        return '/';
      }
      return brandRemoteRels
        .map((brand) => {
          const brandName = brand.brand.name.toString();
          const deviceType = brand.deviceType.name.toString();
          return `${brandName}-${deviceType}`;
        })
        .toString();
    },
    getSpRemoteRels(spRemoteRels) {
      if (spRemoteRels && spRemoteRels.find((service) => service.serviceProvider)) {
        return spRemoteRels.map((service) => service.serviceProvider.name).toString();
      }
      return '/';
    },
  },
  data() {
    return {
      brandSelect: undefined,
      providerSelect: undefined,
      deviceTypeSelect: undefined,
      remoteList: undefined,
      brandList: undefined,
      providerList: undefined,
      deviceTypeList: undefined,
      searchKeyword: '',
      requestParams: {},
      filterSelected: this.$constRepository.remoteController.FILTER_OPTION_REMOTE_ID.value,
      filterOptions: [this.$constRepository.remoteController.FILTER_OPTION_REMOTE_ID, this.$constRepository.remoteController.FILTER_OPTION_REMOTE_NAME],
      remoteRelDtosSelected: this.$constRepository.remoteController.FILTER_OPTION_REMOTE_BRAND,
      filterRemoteRelDtos: [
        this.$constRepository.remoteController.FILTER_OPTION_REMOTE_BRAND,
        this.$constRepository.remoteController.FILTER_OPTION_REMOTE_PROVIDER,
      ],
      remoteControllerList: undefined,
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'remoteControllerId', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.remoteController.FILTER_OPTION_REMOTE_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.remoteController.FILTER_OPTION_REMOTE_ID.value:
          this.$set(this.requestParams, 'remoteControllerId', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listRemoteController();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listRemoteController(this.requestParams.name, this.pageInfo);
      }
    },
    async listRemoteController() {
      const params = {
        name: this.requestParams.name,
        remoteControllerId: this.requestParams.remoteControllerId,
        deviceTypeId: this.deviceTypeSelect,
        brandId: '',
        serviceProviderId: '',
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      if (this.brandSelect) {
        params.brandId = lodash.split(this.brandSelect.key, '-', 2)[0];
      }
      if (this.providerSelect) {
        params.serviceProviderId = lodash.split(this.providerSelect.key, '-', 2)[0];
      }
      const response = await this.$apiManager.remoteControllers.getRemoteController(params);
      this.remoteControllerList = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    remoteChange(value) {
      this.brandSelect = undefined;
      this.providerSelect = undefined;
      this.filterRemoteRelDtos.forEach((remote) => {
        if (remote.value === value) {
          this.remoteRelDtosSelected = remote;
        }
      });
    },
    getBrands() {
      this.remoteList = null;
      this.$apiManager.brand.getBrands({ unpaged: true }).then((response) => {
        this.brandList = response.data.elements;
      });
    },
    getProvider() {
      this.remoteList = null;
      this.$apiManager.serviceProvider.getServiceProviders({ unpaged: true }).then((response) => {
        this.providerList = response.data.elements;
      });
    },
    getDeviceType() {
      this.$apiManager.deviceType.getDeviceTypes({ unpaged: true }).then((response) => {
        this.deviceTypeList = response.data.elements;
      });
    },
    jumpToCreate() {
      this.$router.push({ name: 'remoteControllerCreator' });
    },
    jumptoEdit(info) {
      this.$router.push({ name: 'remoteControllerEdit', params: { remoteController: info } });
    },
    jumptoI18n(info) {
      this.$router.push({ name: 'remoteControllerI18n', params: { remoteController: info } });
    },
    async onDelete(remoteController) {
      await this.$apiManager.remoteControllers.deleteRemoteController(remoteController.remoteControllerId);
      if (this.remoteControllerList.length === 1 && this.pageInfo.page !== 1) {
        this.pageInfo.page -= 1;
      }
      this.loadedPageData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.remoteController-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.remoteController-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
</style>
