<template>
  <div class="w-full" style="display: flex; flex-flow: row wrap">
    <a-col
      class="w-1/2"
      v-if="
        form.type.editMode != null &&
        (form.type.editMode === $constRepository.productFormItem.editMode.fixed ||
          ![$constRepository.productFormItem.type.string, $constRepository.productFormItem.type.file].includes(
            form.type.type,
          ))
      "
    >
      <a-form-model-item
      key="defaultValue"
        label="默认值"
        prop="type.defaultValue"
        :rules="
          form.type.editMode === $constRepository.productFormItem.editMode.fixed
            ? { required: true, message: '默认信息 不能为空', trigger: 'blur' }
            : undefined
        "
        ><a-input v-model="form.type.defaultValue" /></a-form-model-item
    ></a-col>
    <template
      v-if="
        form.type.editMode === $constRepository.productFormItem.editMode.input &&
        form.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL
      "
    >
      <a-col class="w-1/2">
        <a-form-model-item
          key="min"
          :label="`${isStringType?'字符串长度':''}最小值`"
          prop="type.range.min"
          :rules="{ required: true, message: `${isStringType?'字符串长度':''}最小值 不能为空`, trigger: 'blur' }"
          ><a-input v-model="form.type.range.min"
        /></a-form-model-item>
      </a-col>
      <a-col class="w-1/2">
        <a-form-model-item
           key="max"
          :label="`${isStringType?'字符串长度':''}最大值`"
          prop="type.range.max"
          :rules="{ required: true, message: `${isStringType?'字符串长度':''}最大值 不能为空`, trigger: 'blur' }"
          ><a-input v-model="form.type.range.max"
        /></a-form-model-item>
      </a-col>
    </template>
    <a-col class="w-full" v-if="form.type.editMode === $constRepository.productFormItem.editMode.select">

        <a-form-model-item label="选项配置" :wrapper-col="{ span: 19 }" >
      <a-card
        style="margin-bottom: 1rem; width: 100%;"
      >
        <a-table
          :data-source="form.type.options"
          :row-key="(_, index) => index"
          :pagination="false"
          :scroll="{ y: '500px' }"
        >
          <a-table-column data-index="value" key="value" title="值" align="center">
            <template slot-scope="text, record">
              <a-input :maxLength="64" v-model="record.value"></a-input>
            </template>
          </a-table-column>
          <a-table-column data-index="defaultMessage" key="defaultMessage" title="默认信息" align="center">
            <template slot-scope="text, record">
              <a-input v-model="record.description.defaultMessage"></a-input>
            </template>
          </a-table-column>
          <a-table-column key="operator" title="操作">
            <template slot-scope="text, record, index">
              <a-button
                v-if="showDynamic && text.value && $hasPermissions(['rcs:dll_file:list'])"
                type="link"
                size="small"
                @click="jumpToDynamic(text)"
                >动态库文件管理</a-button
              >
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 添加 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item key="0">
                    <a-button type="link" size="small" @click="addOption(index)">向上添加</a-button>
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="1">
                    <a-button type="link" size="small" @click="addOption(index + 1)">向下添加</a-button>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-button
                v-if="form.type.options.length > 1"
                type="link"
                size="small"
                class="delete-btn"
                @click="delOption(index)"
                >删除</a-button
              >
            </template>
          </a-table-column>
        </a-table>
      </a-card>
    </a-form-model-item>
    </a-col>
    <product-add-file-type-modal
      :currentItem="currentItem"
      :visible.sync="modalVisible"
      v-if="modalVisible"
      @editItem="editItem"
      ref="productAddFileTypeModal"
    />
    <ProductConfigurationDynamicDepotModal ref="productConfigurationDynamicDepotModal" />
  </div>
</template>

<script>
import productAddFileTypeModal from '../../product-configuration/components/productAddFileTypeModal';
import ProductConfigurationDynamicDepotModal from '../../product-configuration/ProductConfigurationDynamicDepotModal';

export default {
  name: 'productFormItem',
  props: {
    formInfo: {
      required: true,
    },
    metaData: {
      required: false,
    },
    showDynamic: {
      required: false,
      default: false,
    },
  },
  components: { productAddFileTypeModal, ProductConfigurationDynamicDepotModal },
  data() {
    return {
      form: this.formInfo,
      modalVisible: false,
      currentItem: {
        value: '',
        description: {},
        fileInfo: {},
      },
      updateIndex: -1,
    };
  },
  methods: {
    delOption(index) {
      this.form.type.options.splice(index, 1);
    },
    addOption(index) {
      this.form.type.options.splice(index, 0, {
        value: '',
        description: {
          key: '',
          defaultMessage: '',
        },
      });
    },
    handleEdit(scope, index) {
      const obj = {};
      obj.value = scope.value ?? '';
      obj.description = scope.description ?? {};
      obj.fileInfo = scope.fileInfo ?? {};
      if (scope.fileInfo !== undefined) {
        obj.fileList = [
          {
            uid: scope.fileInfo.md5 ?? '',
            filePath: scope.fileInfo.filePath ?? '',
            name: scope.fileInfo.originalFilename ?? '',
          },
        ];
      } else {
        obj.fileList = [];
      }
      this.currentItem = obj;
      this.updateIndex = index;
      this.showKey = true;
      this.modalVisible = true;
    },
    editItem(item) {
      if (this.updateIndex !== -1) {
        this.form.type.options.splice(this.updateIndex, 1, item);
        this.updateIndex = -1;
      }
    },
    jumpToDynamic(item) {
      this.$refs.productConfigurationDynamicDepotModal.showDialog(
        {
          configurationItemId: this.form.id,
          soFilename: item.value,
          identifier: this.form.identifier,
        },
        this.form.description.defaultMessage,
      );
    },
  },
  computed: {
    isStringType() {
      return this.form.type.type === this.$constRepository.product.DATA_PRIMITIVE_TYPES.STRING || this.form.type.type === this.$constRepository.product.DATA_PRIMITIVE_TYPES.STRING_ARRAY;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('update:formInfo', val);
      },
    },
    formInfo(val) {
      this.form = val;
    },
  },
};
</script>

<style lang="less" scoped>
.edit-btn {
  color: green;
}
.update-btn {
  color: blue;
}

.delete-btn {
  color: red;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-full {
  width: 100%;
  /deep/ .ant-form-item-label {
    width: 70px;
  }
}
// /deep/ .undefined-item-content{
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 48px;
// }
</style>
