<template>
  <div class="content-layout">
    <div class="property-operations">

      <div class="data-filter">
        <a-form layout="inline">
                 <a-form-item  style="margin: 0;">
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 120px">
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
          <a-form-item >
            <a-input v-model="searchKeyword" class="search-keyword"  placeholder="请输入" allowClear @keyup.enter.native="doSearch"></a-input>
          </a-form-item>
          <a-form-item label="节点类型">
        <a-select placeholder="节点类型" allow-clear v-model="requestParams.propertyType" style="width: 120px">
          <a-select-option v-for="filter in propertyTypeOption" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
        </a-form-item>
        <!-- <a-form-item label="属性">
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 200px">
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
      </a-form-item> -->

        <a-form-item ><a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button></a-form-item>
        </a-form>
      </div>
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:product_property:create'])"
          type="primary"
          class="add-btn"
          @click="handleCreator()"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.property.columns"
      :data-source="tableDate"
      class="property-table"
      rowKey="id"
    >
      <template slot="propertyType" slot-scope="scope">
        {{ scope === $constRepository.property.FILTER_OPTION_PROPERTY_NODE_FATHER.value ? '父节点' : '子节点' }}
      </template>
      <!-- <template slot="createdTime" slot-scope="scope">{{
        scope | parseTime | orNA
      }}</template> -->
      <template slot="lastModificationTime" slot-scope="scope">{{
        scope | parseTime | orNA
      }}

    </template>
      <span slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:product_property:update'])"
          class="green-btn"
          ghost
          @click="jumptoEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button
          v-if="$hasPermissions(['rcs:product_property:get'])"
          class="green-btn"
          ghost
          @click="jumptoDetail(scope)"
          >详情</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:product_property:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除属性?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'PropertyManagement',
  components: {},
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.property.FILTER_OPTION_PROPERTY_IDENTIFER.value,
      filterOptions: this.$constRepository.property.FILTER_OPTIONS,
      propertyTypeOption: this.$constRepository.property.FILTER_NODES,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      moment,
    };
  },
  methods: {
    processSearchKeyword() {
      this.$set(this.requestParams, 'searchKey', undefined);
      this.$set(this.requestParams, 'id', undefined);
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'identifier', undefined);
      this.$set(this.requestParams, 'encode', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.property.FILTER_OPTION_PROPERTY_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.property.FILTER_OPTION_PROPERTY_IDENTIFER.value:
          this.$set(this.requestParams, 'identifier', this.searchKeyword);
          break;
        case this.$constRepository.property.FILTER_OPTION_PROPERTY_ENCODE.value:
          this.$set(this.requestParams, 'encode', this.searchKeyword);
          break;
        case this.$constRepository.property.FILTER_OPTION_PROPERTY_ID.value:
          this.$set(this.requestParams, 'id', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listProperties() {
      const params = {
        // id: this.requestParams.id,
        // searchKey: this.requestParams.searchKey,
        ...this.requestParams,
        propertyType: this.requestParams.propertyType,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.property.getProperties(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listProperties();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProperties();
      }
    },
    handleCreator() {
      this.$router.push({ name: 'propertyCreator' });
    },
    jumptoEdit(record) {
      this.$router.push({ name: 'propertyEditor', params: { id: record.id } });
    },
    jumptoDetail(record) {
      this.$router.push({ name: 'propertyDetail', params: { id: record.id } });
    },
    onDelete(record) {
      this.$apiManager.property.deleteProperty(record.id).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.property-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
</style>
