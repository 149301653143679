const FILTER_OPTION_PROPERTY_ID = {
  value: '0',
  label: '属性ID',
};
const FILTER_OPTION_PROPERTY_NAME = {
  value: '1',
  label: '属性名称',
};
const FILTER_OPTION_PROPERTY_IDENTIFER = {
  value: '2',
  label: '属性标识符',
};
const FILTER_OPTION_PROPERTY_ENCODE = {
  value: '3',
  label: '属性唯一标识',
};
const FILTER_OPTIONS = [FILTER_OPTION_PROPERTY_IDENTIFER, FILTER_OPTION_PROPERTY_NAME, FILTER_OPTION_PROPERTY_ENCODE];
const FILTER_OPTION_PROPERTY_NODE_FATHER = {
  value: 1,
  label: '父节点',
};
const FILTER_OPTION_PROPERTY_NODE_CHILD = {
  value: 2,
  label: '子节点',
};
const FILTER_NODES = [FILTER_OPTION_PROPERTY_NODE_FATHER, FILTER_OPTION_PROPERTY_NODE_CHILD];
const propertyType = {
  root: 1,
  child: 2,
};
const accessMode = {
  readOnly: 1,
  writeOnly: 2,
  readWrite: 3,
};
const columns = [
  {
    title: '属性标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
  },
  {
    title: '属性定义唯一标识',
    dataIndex: 'encode',
    key: 'encode',
    align: 'center',
  },
  {
    title: '属性名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '节点类型',
    dataIndex: 'propertyType',
    key: 'propertyType',
    scopedSlots: { customRender: 'propertyType' },
    align: 'center',
  },

  {
    title: '最后更新时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '更新人',
    dataIndex: 'updaterName',
    key: 'updaterName',
    width: '10%',
    scopedSlots: { customRender: 'updaterName' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default {
  FILTER_NODES,
  FILTER_OPTION_PROPERTY_ID,
  FILTER_OPTION_PROPERTY_NAME,
  FILTER_OPTION_PROPERTY_IDENTIFER,
  FILTER_OPTION_PROPERTY_ENCODE,
  FILTER_OPTION_PROPERTY_NODE_FATHER,
  FILTER_OPTION_PROPERTY_NODE_CHILD,
  FILTER_OPTIONS,
  columns,
  propertyType,
  accessMode,
};
