<template>
  <a-card title="父节点属性信息">
    <a-button slot="extra" type="link" @click="jumpToParentDetail">详情</a-button>
    <a-descriptions bordered>
      <a-descriptions-item label="属性ID">{{ property.id }} </a-descriptions-item>
      <a-descriptions-item label="属性名称"> {{ property.description.defaultMessage }} </a-descriptions-item>
      <a-descriptions-item label="属性标识符"> {{ property.identifier }} </a-descriptions-item>
      <a-descriptions-item label="属性定义唯一标识"> {{ property.encode }} </a-descriptions-item>
      <a-descriptions-item label="属性读写权限">
        <div v-if="property.accessMode === $constRepository.property.accessMode.readOnly">只读</div>
        <div v-else-if="property.accessMode === $constRepository.property.accessMode.writeOnly">只写</div>
        <div v-else-if="property.accessMode === $constRepository.property.accessMode.readWrite">可读写</div>
        <div v-else>未知</div>
      </a-descriptions-item>
      <a-descriptions-item label="节点类型">
        <div v-if="property.propertyType === $constRepository.property.propertyType.root">父节点</div>
        <div v-else-if="property.propertyType === $constRepository.property.propertyType.child">子节点</div>
        <div v-else>未知</div></a-descriptions-item
      >
      <a-descriptions-item label="属性类型">
        <span>{{ propertyType(property) }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="编辑方式">
        <span>{{ propertyEditMode(property) }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="默认值">
        <span>{{ property.type.defaultValue || '无' }}</span>
      </a-descriptions-item>
      <template v-if="property.type.editMode == $constRepository.productFormItem.editMode.input">
        <a-descriptions-item label="范围">
          <span v-if="property.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
            `${property.type.range.min}-${property.type.range.max}`
          }}</span>
          <span v-else> 无 </span>
        </a-descriptions-item>
      </template>
      <template v-if="property.type.editMode == $constRepository.productFormItem.editMode.select">
        <a-descriptions-item label="选项">
          <p v-for="(option, index) in property.type.options" :key="index">
            {{ `${option.value}:${option.description.defaultMessage}` }}
          </p>
        </a-descriptions-item>
      </template>
    </a-descriptions>
  </a-card>
</template>

<script>
export default {
  name: 'ParentPropertyCard',
  props: ['parentId', 'metaData'],
  computed: {
    propertyType() {
      return (property) => {
        const res = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        if (res) {
          return res.name;
        }
        return '';
      };
    },
    propertyEditMode() {
      return (property) => this.$constRepository.productFormItem.editModeText[property.type.editMode] || '';
    },
  },
  data() {
    return {
        property: {
            identifier: null,
        name: null,
        propertyType: null,
        accessMode: null,
        description: {
          key: '',
          defaultMessage: '',
        },
        type: {
          type: undefined,
          editMode: undefined,
          defaultValue: '',
          range: {
            min: '',
            max: '',
          },
          options: [
            {
              value: '',
              description: {
                key: '',
                defaultMessage: '',
              },
            },
          ],
          defaultValueDescription: {
            key: '',
            defaultMessage: '',
          },
        },
        },
    };
  },
  methods: {
    jumpToParentDetail() {
         const url = this.$router.resolve({ name: 'propertyDetail', params: { id: this.property.id } });
         window.open(url.href, '_blank');
    },
    async fetchParent() {
        const params = {
        id: this.parentId,
      };
      const response = await this.$apiManager.property.getPropertiesById(params);
      this.property = response.data;
    },
  },
  mounted() {
    this.fetchParent();
  },
};
</script>
