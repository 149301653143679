<template>
  <div class="container">
    <a-form-model class="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-tabs default-active-key="basicInfo">
        <a-tab-pane key="basicInfo" tab="基础信息">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-1/2">
          <a-form-model-item label="服务标识符" prop="identifier">
            <a-input v-model="form.identifier" /> </a-form-model-item
        ></a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="服务描述" prop="description.defaultMessage">
            <a-input v-model="form.description.defaultMessage" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="超时时间" prop="timeout">
            <a-input suffix="ms" v-model="form.timeout" /> </a-form-model-item
        ></a-col>
        <a-col class="w-full">
          <a-form-model-item label="参数项列表" prop="parameters">
            <div v-for="(parameter, index) in form.parameters" :key="index">
              <a-card title="参数项" style="margin-top: 10px">
                <template #extra><a-button type="link" @click="delParameter(index)">x</a-button></template>
                <a-form-model :model="parameter" layout="vertical" ref="childForm">
                  <a-row :gutter="[24, 16]" type="flex" align="bottom">
                    <a-col class="w-1/2">
                      <a-form-model-item
                        label="参数项标识符"
                        prop="identifier"
                        :rules="{ required: true, message: '参数项标识符 不能为空', trigger: 'blur' }"
                      >
                        <a-input v-model="parameter.identifier" /> </a-form-model-item
                    ></a-col>
                    <a-col class="w-1/2">
                      <a-form-model-item label="参数项名称" prop="description.defaultMessage">
                        <a-input v-model="parameter.description.defaultMessage" /> </a-form-model-item
                    ></a-col>
                    <a-col class="w-1/2">
                      <a-form-model-item
                        label="字段的存储类型"
                        prop="type.type"
                        :rules="{ required: true, message: '字段的存储类型 不能为空', trigger: 'change' }"
                      >
                        <a-select v-model="parameter.type.type">
                          <a-select-option
                            v-for="primitiveType in metaData.dataPrimitiveTypes"
                            :key="primitiveType.value"
                            :value="primitiveType.value"
                            >{{ primitiveType.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-model-item></a-col
                    >
                    <a-col class="w-1/2">
                      <a-form-model-item
                        label="字段的编辑方式"
                        prop="type.editMode"
                        :rules="{ required: true, message: '字段的编辑方式 不能为空', trigger: 'change' }"
                      >
                        <a-select v-model="parameter.type.editMode">
                          <a-select-option
                            v-for="editMode in metaData.editModes"
                            :key="editMode.value"
                            :value="editMode.value"
                            >{{ editMode.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-model-item></a-col
                    >

                    <template
                      v-if="
                        parameter.type.editMode === $constRepository.productFormItem.editMode.input &&
                        parameter.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL
                      "
                    >
                      <a-col class="w-1/2">
                        <a-form-model-item
                          label="最小值"
                          prop="type.range.min"
                          :rules="{ required: true, message: '最小值 不能为空', trigger: 'blur' }"
                          ><a-input v-model="parameter.type.range.min"
                        /></a-form-model-item>
                      </a-col>
                      <a-col class="w-1/2">
                        <a-form-model-item
                          label="最大值"
                          prop="type.range.max"
                          :rules="{ required: true, message: '最大值 不能为空', trigger: 'blur' }"
                          ><a-input v-model="parameter.type.range.max"
                        /></a-form-model-item>
                      </a-col>
                    </template>
                    <a-col
                      class="w-1/2"
                      v-if="
                        parameter.type.editMode === $constRepository.productFormItem.editMode.fixed ||
                        ![
                          $constRepository.productFormItem.type.string,
                          $constRepository.productFormItem.type.file,
                        ].includes(parameter.type.type)
                      "
                    >
                      <a-form-model-item
                        v-if="parameter.type.editMode !== null"
                        label="默认值"
                        prop="type.defaultValue"
                        :rules="
                          parameter.type.editMode === $constRepository.productFormItem.editMode.fixed
                            ? { required: true, message: '默认值 不能为空', trigger: 'blur' }
                            : undefined
                        "
                        ><a-input v-model="parameter.type.defaultValue"
                      /></a-form-model-item>
                    </a-col>
                    <a-row
                      style="width: 100%"
                      type="flex"
                      align="bottom"
                      v-if="parameter.type.editMode === $constRepository.productFormItem.editMode.select"
                    >
                      <a-col class="w-full">
                        <a-table :data-source="parameter.type.options" row-key="value">
                          <a-table-column data-index="value" key="value" title="值" align="center">
                            <template slot-scope="text, record">
                              <a-input v-model="record.value"></a-input>
                            </template>
                          </a-table-column>
                          <a-table-column
                            data-index="defaultMessage"
                            key="defaultMessage"
                            title="默认信息"
                            align="center"
                          >
                            <template slot-scope="text, record">
                              <a-input v-model="record.description.defaultMessage"></a-input>
                            </template>
                          </a-table-column>
                          <a-table-column key="operator" title="操作" align="center">
                            <template slot-scope="text, record, optionIndex">
                              <a-dropdown :trigger="['click']">
                                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                                  添加 <a-icon type="down" />
                                </a>
                                <a-menu slot="overlay">
                                  <a-menu-item key="0">
                                    <a-button type="link" size="small" @click="addParameterOption(index, optionIndex)"
                                      >向上添加</a-button
                                    >
                                  </a-menu-item>
                                  <a-menu-divider />
                                  <a-menu-item key="1">
                                    <a-button
                                      type="link"
                                      size="small"
                                      @click="addParameterOption(index, optionIndex + 1)"
                                      >向下添加</a-button
                                    >
                                  </a-menu-item>
                                </a-menu>
                              </a-dropdown>
                              <a-button
                                v-if="parameter.type.options.length > 1"
                                type="link"
                                size="small"
                                class="delete-btn"
                                @click="delParameterOption(index, optionIndex)"
                                >删除</a-button
                              >
                            </template>
                          </a-table-column>
                        </a-table>
                      </a-col>
                    </a-row>
                  </a-row>
                </a-form-model>
              </a-card>
            </div>
          </a-form-model-item></a-col
        >
        <a-col class="w-full" :push="14">
          <a-form-item>
            <a-button shape="circle" @click="addParameter"> + </a-button>
          </a-form-item>
        </a-col>
        </a-row></a-tab-pane>
        <a-tab-pane key="codeEdit" tab="代码编辑器">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-full">
          <a-form-model-item label="服务编辑器" prop="description">
            <vue-json-editor
              v-model="form"
              :showBtns="false"
              :mode="'code'"
              lang="zh"
              style="height: 800px"
            /> </a-form-model-item
        ></a-col>

      </a-row>
      </a-tab-pane></a-tabs>

      <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-full">
          <a-form-model-item class="group-btn">
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button class="creator-btn" @click="cancel">取消</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';

export default {
  name: 'ProductServiceCreator',
  components: { vueJsonEditor },
  async mounted() {
    await this.getMetaData();
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      rules: {
        identifier: [{ required: true, message: '服务标识符 不能为空', trigger: 'blur' }],
        timeout: [{ required: true, validator: this.timeValidator, trigger: 'blur' }],
        'description.defaultMessage': [{ required: true, message: '服务描述 不能为空', trigger: 'blur' }],
      },
      form: {
        identifier: null,
        remark: null,
        timeout: null,
        description: {
          key: null,
          defaultMessage: null,
        },
        parameters: [
          // {
          //   identifier: null,
          //   description: {
          //     key: null,
          //     defaultMessage: null,
          //   },
          //   type: {
          //     type: null,
          //     editMode: null,
          //     defaultValue: '',
          //   },
          // },
        ],
      },
      metaData: {},
    };
  },
  methods: {
    timeValidator(fields, option, callback) {
      if (!option) {
        callback('超时时间 不能为空');
      }
      const regex = /^\d+$/;
      if (!regex.test(option)) {
        callback('超时时间 只能为数字');
      }
      callback();
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
      this.metaData.dataPrimitiveTypes = this.metaData.dataPrimitiveTypes?.filter((type) => type.value !== 9);
    },
    addParameterOption(index, optionIndex) {
      this.form.parameters[index].type.options.splice(optionIndex, 0, {
        value: '',
        description: {
          key: '',
          defaultMessage: '',
        },
      });
    },
    addParameter() {
      this.form.parameters.push({
        identifier: '',
        description: {
          key: null,
          defaultMessage: '',
        },
        type: {
          type: null,
          editMode: null,
          defaultValue: '',
        },
      });
    },
    delParameterOption(index, optionIndex) {
      this.form.parameters[index].type.options.splice(optionIndex, 1);
    },
    delParameter(index) {
      this.form.parameters.splice(index, 1);
    },
    cancel() {
      this.$router.back();
    },
    onSubmit() {
      let validFlag = true;
      this.$refs.form.validate((valid) => {
        if (!valid) {
          validFlag = false;
        }
      });
      if (this.form.parameters.length > 0) {
        this.$refs.childForm.forEach((f) => {
          f.validate((relsValid) => {
            if (!relsValid) {
              validFlag = false;
            }
          });
        });
      }
      if (validFlag) {
        this.$apiManager.productService.createProductService(this.form).then(() => {
          this.$message.success('创建成功');
          this.$router.back();
        });
      } else {
          this.$message.warn('基础信息存在未填写的数据');
        }
    },
  },
  watch: {
    'form.parameters': {
      handler(value) {
        value.forEach((parameter) => {
          if (parameter.type.editMode !== null) {
            if (parameter.type.editMode === 0 && parameter.type.range === undefined) {
              this.$set(parameter.type, 'range', {
                min: '',
                max: '',
              });
              // eslint-disable-next-line no-param-reassign
              parameter.type.defaultValue = '';
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.options;
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.defaultValueDescription;
            }
            if (parameter.type.editMode === 1 && parameter.type.options === undefined) {
              this.$set(parameter.type, 'options', [
                {
                  value: '',
                  description: {
                    key: '',
                    defaultMessage: '',
                  },
                },
              ]);
              // eslint-disable-next-line no-param-reassign
              parameter.type.defaultValue = '';
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.range;
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.defaultValueDescription;
            }
            if (parameter.type.editMode === 2 && parameter.type.defaultValueDescription === undefined) {
              this.$set(parameter.type, 'defaultValueDescription', {
                key: '',
                defaultMessage: '',
              });
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.options;
              // eslint-disable-next-line no-param-reassign
              delete parameter.type.range;
            }
          }
          if (parameter.type.defaultValue !== '' && parameter.type.defaultValueDescription) {
            // eslint-disable-next-line no-param-reassign
            parameter.type.defaultValueDescription.defaultMessage = parameter.type.defaultValue;
          }
        });
      },
      deep: true,
    },
    'form.description.defaultMessage': {
      handler(value) {
        this.form.remark = value;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  .form {
     // padding-top: 30px;
    width: 100%;
    /deep/ .ant-form-item-label {
      width: 120px;
    }
  }
}
.option_descriptions {
  /deep/ .ant-descriptions-item-content {
    width: 115px;
    word-wrap: break-word;
  }
  /deep/ .ant-descriptions-item-label {
    width: 60px;
  }
}
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.default-message-edit {
  height: 8vw;
  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  margin-left: 2%;
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 120px;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-full {
  width: 100%;
}
.center-contaner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.option-delete-button {
  display: inline;
  cursor: pointer;
  font-size: 20px;
  color: #999;
  transition: all 0.3s;
}
.option-delete-button:hover {
  color: #777;
}
.option-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
