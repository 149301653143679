import http from '../http-engine';

export default {
  getProductConfigurationItems(params) {
    return http.get('/api/v1/productConfigurationItems', { params });
  },
  createProductConfigurationItems(params) {
    return http.post('/api/v1/productConfigurationItems', params);
  },
  updateProductConfigurationItems(params) {
    const param = {
      fields: ['remark', 'description', 'type', 'encode', 'itemType'],
      data: params,
    };
    return http.patch(`/api/v1/productConfigurationItems/${params.id}`, param);
  },
  deleteProductConfigurationItems(params) {
    return http.delete(`/api/v1/productConfigurationItems/${params}`);
  },
  getProductConfigurationById(id, headers) {
    return http.get(`/api/v1/productConfigurationItems/${id}`, { headers });
  },
};
