const FILTER_OPTION_SERVICE_NAME = {
  value: '1',
  label: '服务项名称',
};
const FILTER_OPTION_SERVICE_IDENTIFER = {
  value: '2',
  label: '服务项标识符',
};

const FILTER_OPTIONS = [FILTER_OPTION_SERVICE_IDENTIFER, FILTER_OPTION_SERVICE_NAME];
const columns = [
  {
    title: '服务项标识符',
    dataIndex: 'identifier',
    key: 'identifier',
    align: 'center',
    width: '12%',
  },
  {
    title: '服务描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
    align: 'center',
    width: '15%',
  },
  {
    title: '超时时间',
    dataIndex: 'timeout',
    key: 'timeout',
    scopedSlots: { customRender: 'timeout' },
    align: 'center',
    width: '10%',
  },
  // {
  //   title: '服务项备注',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   align: 'center',
  //   width: '20%',
  // },
  {
    title: '最后更新时间',
    dataIndex: 'lastModificationTime',
    key: 'lastModificationTime',
    width: '20%',
    scopedSlots: { customRender: 'lastModificationTime' },
    align: 'center',
  },
  {
    title: '更新人',
    dataIndex: 'updaterName',
    key: 'updaterName',
    width: '10%',
    scopedSlots: { customRender: 'updaterName' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: '20%',
  },
];
export default {
  FILTER_OPTIONS,
  FILTER_OPTION_SERVICE_IDENTIFER,
  FILTER_OPTION_SERVICE_NAME,
  columns,
};
