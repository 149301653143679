<template>
  <div class="container">
    <a-form-model class="form" :model="form" :rules="rules" ref="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-tabs default-active-key="basicInfo">
        <a-tab-pane key="basicInfo" tab="基础信息">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
            <a-col class="w-1/2">
              <a-form-model-item label="属性名称" prop="name">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col class="w-1/2">
              <a-form-model-item prop="propertyType">
                <template slot="label">
                  是否父节点
                  <a-popover trigger="hover">
                    <template slot="content">
                      <ParentPropertyCard :parentId="form.parentId" :metaData="metaData" />
                    </template>
                    <a-icon v-if="form.parentId" style="margin-left: 0.5rem;margin-top: 0.2rem; cursor: pointer"
                      type="info-circle" />
                  </a-popover>
                </template>
                <a-select :disabled="true" v-model="form.propertyType" @change="cleanPropertyId">
                  <a-select-option v-for="(filter, key) in filterOptions" :key="key" :value="filter.value">{{
                    filter.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col class="w-1/2">
              <a-form-model-item label="属性标识符" prop="identifier">
                <a-input :disabled="true" v-if="form.propertyType === $constRepository.property.propertyType.child"
                  v-model="form.identifier" @click="handlePropertySelect">
                  <a-tooltip slot="suffix" title="Extra information">
                    <a-icon type="arrow-right" style="color: rgba(0, 0, 0, 0.45)" />
                  </a-tooltip>
                </a-input>
                <a-input v-else :disabled="true" v-model="form.identifier" />
              </a-form-model-item>
            </a-col>
            <a-col class="w-1/2">
              <a-form-model-item label="属性定义唯一标识" prop="encode">
                <a-input v-model="form.encode" /> </a-form-model-item></a-col>
            <a-col class="w-1/2">
              <a-form-model-item label="属性的读写权限" prop="accessMode">
                <a-select v-model="form.accessMode">
                  <a-select-option :value="$constRepository.property.accessMode.readOnly">只读</a-select-option>
                  <a-select-option :value="$constRepository.property.accessMode.writeOnly">只写</a-select-option>
                  <a-select-option :value="$constRepository.property.accessMode.readWrite">可读写</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col class="w-1/2">
              <a-form-model-item label="字段的存储类型" prop="type.type">
                <a-select v-model="form.type.type">
                  <a-select-option v-for="primitiveType in metaData.dataPrimitiveTypes" :key="primitiveType.value"
                    :value="primitiveType.value">{{ primitiveType.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col class="w-1/2">
              <a-form-model-item label="字段的编辑方式" prop="type.editMode">
                <a-select v-model="form.type.editMode">
                  <a-select-option v-for="editMode in metaData.editModes" :key="editMode.value"
                    :value="editMode.value">{{
                      editMode.name
                    }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <product-form-item :metaData="metaData" :formInfo.sync="form" />
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="codeEdit" tab="代码编辑器">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
            <a-col class="w-full">
              <a-form-model-item label="代码编辑器" prop="form">
                <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 700px" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
      <a-row :gutter="[24, 0]" type="flex" align="bottom" style="margin: 0 2% ">
        <a-col class="w-full">
          <a-form-item class="group-btn">
            <a-button type="primary" @click="onSubmit">保存</a-button>
            <a-button class="creator-btn" @click="$router.back()">返回</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form-model>
    <property-select-modal ref="propertySelectModal" />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import PropertySelectModal from './components/PropertySelectModal';
import productFormItem from '../product/components/productFormItem';
import ParentPropertyCard from './components/ParentPropertyCard';

export default {
  name: 'PropertyEditor',
  components: {
    vueJsonEditor, PropertySelectModal, productFormItem, ParentPropertyCard,
  },
  async mounted() {
    if (this.$route.params.id) {
      this.listPropertiesById(this.$route.params.id);
    }
    await this.getMetaData();
  },
  data() {
    return {
      filterOptions: this.$constRepository.property.FILTER_NODES,
      rules: {
        name: [{ required: true, message: '属性名称 不能为空', trigger: 'blur' }],
        identifier: [
          { required: true, message: '属性标识符 不能为空 请在编辑器中添加identifier字段', trigger: 'blur' },
        ],
        propertyType: [{ required: true, message: '节点类型 不能为空', trigger: 'blur' }],
        description: [{ required: true, message: '属性描述 不能为空', trigger: 'blur' }],
        accessMode: [{ required: true, message: '属性的读写权限 不能为空', trigger: 'blur' }],
        'type.type': [{ required: true, message: '字段的存储类型 不能为空', trigger: 'blur' }],
        'type.editMode': [{ required: true, message: '字段的编辑方式 不能为空', trigger: 'blur' }],
      },
      form: {
        identifier: null,
        name: null,
        propertyType: null,
        accessMode: null,
        encode: null,
        description: {
          key: '',
          defaultMessage: '',
        },
        type: {
          type: undefined,
          editMode: undefined,
          defaultValue: '',
        },
      },
      metaData: {},
    };
  },
  methods: {
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
      this.metaData.dataPrimitiveTypes = this.metaData.dataPrimitiveTypes?.filter((type) => type.value !== 9);
    },
    async listPropertiesById(ids) {
      const params = { id: ids };
      const response = await this.$apiManager.property.getPropertiesById(params);
      this.form = response.data;
    },
    handlePropertySelect() {
      const test = (select) => {
        this.form.identifier = select[0];
      };
      this.$refs.propertySelectModal.showCreateDialog(test);
    },
    cleanPropertyId() {
      this.form.identifier = '';
      this.form.parentId = '';
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.property.updateProperty(this.form).then(() => {
            this.$message.success('修改成功');
            this.$router.back();
          });
        } else {
          this.$message.warn('基础信息存在未填写的数据');
        }
      });
    },
  },
  watch: {
    'form.type.editMode': {
      handler(value) {
        if (value === 0 && this.form.type.range === undefined) {
          this.$set(this.form.type, 'range', {
            min: '',
            max: '',
          });
          delete this.form.type.options;
          delete this.form.type.defaultValueDescription;
        }
        if (value === 1 && this.form.type.options === undefined) {
          this.$set(this.form.type, 'options', [
            {
              value: '',
              description: {
                key: '',
                defaultMessage: '',
              },
            },
          ]);
          delete this.form.type.range;
          delete this.form.type.defaultValueDescription;
        }
        if (value === 2 && this.form.type.defaultValueDescription === undefined) {
          this.$set(this.form.type, 'defaultValueDescription', {
            key: '',
            defaultMessage: this.form.type.defaultValue,
          });
          delete this.form.type.options;
          delete this.form.type.range;
        }
      },
    },
    'form.type.defaultValue': {
      handler(newValue) {
        if (this.form.type.defaultValueDescription) {
          this.form.type.defaultValueDescription.defaultMessage = newValue;
        }
      },
    },
    'form.name': {
      handler(newValue) {
        this.form.description.defaultMessage = newValue;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;

  .form {
    // padding-top: 30px;
    width: 100%;

    /deep/ .ant-form-item-label {
      width: 140px;
    }
  }
}

/deep/.jsoneditor-poweredBy {
  display: none;
}

// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}

.default-message-edit {
  height: 8vw;

  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}

.edit-btn {
  color: green;
}

.delete-btn {
  color: red;
}

.creator-btn {
  margin-left: 2%;
}

.service-provicer-operations {
  display: flex;

  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }

  .service-table {
    margin-top: 2%;
  }
}

.group-btn {
  margin-left: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-full {
  width: 100%;
}
</style>
