<template>
  <div class="container">
    <a-form-model class="form" :model="form"  :rules="rules" ref="form"   :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-tabs default-active-key="basicInfo">
        <a-tab-pane key="basicInfo" tab="基础信息">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-1/2">
          <a-form-model-item label="配置标识符" prop="identifier">
            <a-input v-model="form.identifier" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="配置项定义唯一标识" prop="encode">
            <a-input v-model="form.encode" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="配置项描述" prop="description.defaultMessage">
            <a-input v-model="form.description.defaultMessage" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="备注" prop="remark">
            <a-input v-model="form.remark" />
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="产品配置项类型">
            <a-select v-model="form.itemType">
              <a-select-option
                v-for="confType in metaData.productConfItemTypes"
                :key="confType.value"
                :value="confType.value"
              >
                {{ confType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="字段的存储类型" prop="type.type">
            <a-select v-model="form.type.type" @change="form.type.editMode = null">
              <a-select-option
                v-for="primitiveType in metaData.dataPrimitiveTypes"
                :key="primitiveType.value"
                :value="primitiveType.value"
                >{{ primitiveType.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col class="w-1/2">
          <a-form-model-item label="字段的编辑方式" prop="type.editMode">
            <a-select v-if="form.type.type === 9" v-model="form.type.editMode">
              <a-select-option :value="1"> 选项型 </a-select-option>
            </a-select>
            <a-select v-else v-model="form.type.editMode">
              <a-select-option v-for="editMode in metaData.editModes" :key="editMode.value" :value="editMode.value">{{
                editMode.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <product-form-item :metaData="metaData" :formInfo.sync="form" />
        </a-row>
        </a-tab-pane>
        <a-tab-pane key="codeEdit" tab="代码编辑器">
          <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-full">
          <a-form-model-item label="代码编辑器" prop="form" >
            <a-tabs type="card" activeKey="1">
              <a-tab-pane key="1" tab="通用类型">
                <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 800px"
              /></a-tab-pane>
            </a-tabs>
          </a-form-model-item>
        </a-col>
        </a-row>
      </a-tab-pane>
      </a-tabs>
      <a-row :gutter="[24, 0]" type="flex" align="bottom" style="width: 96%;margin:1% auto">
        <a-col class="w-full">
          <a-form-item class="group-btn">
            <a-button type="primary" @click="onSubmit">确定</a-button>
            <a-button class="creator-btn" @click="cancel">取消</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import productFormItem from '../product/components/productFormItem';

export default {
  name: 'ProductConfigurationCreator',
  components: { vueJsonEditor, productFormItem },
  async mounted() {
    await this.getMetaData();
  },
  data() {
    return {
      rules: {
        identifier: [{ required: true, message: '配置标识符 不能为空', trigger: 'blur' }],
        encode: [{ required: true, message: '配置项定义唯一标识 不能为空', trigger: 'blur' }],
        'description.defaultMessage': [{ required: true, message: '配置项描述 不能为空', trigger: 'blur' }],
        'type.type': [{ required: true, message: '字段的存储类型 不能为空', trigger: 'blur' }],
        'type.editMode': [{ required: true, message: '字段的编辑方式 不能为空', trigger: 'blur' }],
      },
      form: {
        identifier: null,
        remark: null,
        encode: null,
        description: {
          key: null,
          defaultMessage: null,
        },
        type: {
          type: null,
          editMode: null,
          defaultValue: '',
        },
      },
      metaData: {},
    };
  },
  methods: {
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    cancel() {
      this.$router.back();
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.productConfiguration.createProductConfigurationItems(this.form).then(() => {
            this.$message.success('创建成功');
            this.$router.back();
          });
        } else {
          this.$message.warn('基础信息存在未填写的数据');
        }
      });
    },
  },
  watch: {
    'form.type.defaultValue': {
      handler(newValue) {
        if (this.form.type.defaultValueDescription) {
          this.form.type.defaultValueDescription.defaultMessage = newValue;
        }
      },
    },
    'form.type.editMode': {
      handler(value) {
        if (value === 0 && this.form.type.range === undefined) {
          this.$set(this.form.type, 'range', {
            min: '',
            max: '',
          });
          delete this.form.type.options;
          delete this.form.type.defaultValueDescription;
        }
        if (value === 1 && this.form.type.options === undefined) {
          this.$set(this.form.type, 'options', [
            {
              value: '',
              description: {
                key: '',
                defaultMessage: '',
              },
            },
          ]);
          delete this.form.type.range;
          delete this.form.type.defaultValueDescription;
        }
        if (value === 2 && this.form.type.defaultValueDescription === undefined) {
          this.$set(this.form.type, 'defaultValueDescription', {
            key: '',
            defaultMessage: '',
          });
          delete this.form.type.options;
          delete this.form.type.range;
        }
      },
    },
    // 'form.description.defaultMessage': {
    //   handler(value) {
    //     this.form.remark = value;
    //   },
    // },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  .form {
    width: 100%;
    /deep/ .ant-form-item-label {
      width: 150px;
    }
    /deep/ .ant-form-item{
      margin-bottom: 24px;
    }
    /deep/.ant-form-explain {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  }
  }
}
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.default-message-edit {
  height: 8vw;
  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}
.edit-btn {
  color: green;
}
.delete-btn {
  color: red;
}
.creator-btn {
  margin-left: 2%;
}
.service-provicer-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .service-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 0;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-full {
  width: 100%;
}
</style>
