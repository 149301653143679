<template>
    <div>
        <a-spin :spinning="spinning">
            <loaded-device-property-card :properties="[form]" v-if="form"/>
        </a-spin>
    </div>
</template>

<script>
import LoadedDevicePropertyCard from '../../loaded-device/components/loadedDevicePropertyCard';

export default {
    name: 'PropertyPopover',
    props: ['id'],
    components: { LoadedDevicePropertyCard },
    async mounted() {
        this.listPropertiesById(this.id);
    },
    data() {
        return {
            languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
            spinning: false,
            form: null,
        };
    },
    methods: {
        async listPropertiesById(ids) {
            const params = {
                id: ids,
            };
            const headers = {
                'accept-language': this.languageSelect,
            };
            try {
                this.spinning = true;
                const response = await this.$apiManager.property.getPropertiesById(params, headers);
                this.spinning = false;
                this.form = response.data;
            } catch (error) {
                this.spinning = false;
            } finally {
                this.spinning = false;
            }
        },
    },
};
</script>
