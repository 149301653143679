<template>
  <div class="login_layout">
    <div class="login_box">
      <a-form-model class="login_input_layout" ref="loginForm" :model="form" :rules="rules">
        <div class="login_page_title">
          <h1>RCS管理平台</h1>
        </div>
        <a-form-model-item prop="username">
          <a-input v-model="form.username" size="large" placeholder="请输入账号">
            <template #prefix>
              <span class="login-icon">
                <img src="../../assets/image/icon_login_phone.png" />
              </span>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input
            type="password"
            size="large"
            v-model="form.password"
            @keyup.enter.native="onSubmit"
            placeholder="请输入密码"
          >
            <template #prefix>
              <span class="login-icon">
                <img src="../../assets/image/icon_login_code.png" />
              </span>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button class="login_button" type="primary" @click="onSubmit" size="large">登录</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="foot-bottom">© 2024 福建星网天合智能科技有限公司(星网锐捷控股子公司) 版权所有</div>
  </div>
</template>

<script>
import localStorageUtils from '../../common/local-storage-utils';

export default {
  name: 'Login',
  data() {
    return {
      productList: null,
      form: {
        username: localStorageUtils.getAttribute('username'),
        password: '',
      },
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }],
      },
      allowPost: true,
      count: '',
      timer: null,
    };
  },
  mounted() {
    if (this.$store.state.currentUser) {
      this.$router.push({ path: this.redirect || '/' });
    }
  },
  methods: {
    async onSubmit() {
      this.$refs.loginForm.validate(async (relsValid) => {
        if (relsValid) {
          const h = this.$createElement;

          this.$apiManager.admin
            .login(this.form)
            .then((res) => {
              if (!this.validatePassword(this.form.password)) {
                this.$info({
                  title: '当前密码为弱密码',
                  content: h('div', {}, [h('p', '请前往ERP系统修改密码')]),
                  okText: '立即前往',
                  centered: true,
                  onOk() {
                    window.open('https://erp.nexhome.cn', '_target');
                  },
                });
                return;
              }
              const userDate = res.data;
              userDate.accessToken = `Bearer ${res.data.accessToken}`;
              this.$store.commit('setUserInfo', userDate);
              this.$router.push({ path: this.redirect || '/' });
            })
            .catch(() => {});
        }
      });
    },
    validatePassword(password) {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
      return pattern.test(password);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 10px;
}

.login_layout {
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/image/bg.png);
  flex-direction: row;
  display: flex;
  height: 100vh;
  justify-content: right;
  align-items: center;
}

.login_box {
  display: flex;
  flex-direction: column;
}

.login_input_layout {
  font-size: 0.5rem;
  padding: 6rem 5rem;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0 0 6px #ccc;
  width: 32rem;
  margin-right: 10vw;
}

.login_page_title {
  font-size: 0.8rem;
  color: #333;
  margin-bottom: 3.5rem;
  h1 {
    margin-top: 0rem;
    text-align: center;
    font-weight: bold;
  }
}
.login-icon {
  display: flex;
  margin-left: 4px;
  height: 40px;
  justify-content: center;
  align-items: center;
  img {
    width: 1rem;
    height: 1rem;
  }
}
.login_button {
  width: 100%;
  margin-top: 30px;
  background: #416fff;
}

#get-verification-btn {
  background: #416fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  margin: 0;
}

#verification-input {
  :nth-child(2) {
    :first-child {
      :nth-child(2) {
        padding: 0;
        border: 1px solid #409eff;
      }
    }
  }
}
/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}

.foot-bottom {
  position: fixed;
  bottom: 2rem;
  text-align: center;
  width: 100%;
  color: #666666;
  font-size: 14px;
}
</style>
