<template>
  <div class="content-layout">
    <div class="region-operations">
      <div>
        <a-button v-if="$hasPermissions(['rcs:region:create'])" type="primary" class="add-btn" @click="handleCreator()"
          >新增</a-button
        >
      </div>
      <div class="data-filter">
        <a-select v-if="filterSelected" :default-value="filterSelected.value" style="width: 120px">
          <a-icon slot="suffixIcon" type="down" />
          <a-select-option v-for="provider in filterOptions" :key="provider.value">{{
            provider.label
          }}</a-select-option>
        </a-select>
        <a-input
          v-model="searchKeyword"
          class="search-keyword"
          allow-clear
          placeholder="请输入区域名称"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.region.columns"
      :data-source="tableDate"
      class="region-table"
      rowKey="identifier"
    >
      <template slot="createTime" slot-scope="scope">{{ scope.createdTime | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="pushStatus" slot-scope="scope">
        <a-switch
          @change="switchChange(scope)"
          :disabled="!$hasPermissions(['rcs:region:update'])"
          :checked="scope.pushStatus ? (scope.pushStatus === 1 ? true : false) : false"
        />
      </template>
      <template slot="action" slot-scope="scope">
        <a-button v-if="$hasPermissions(['rcs:region:update'])" class="green-btn" ghost @click="handleEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button
          v-if="$hasPermissions(['rcs:rcs_function:refresh_image_types'])"
          class="green-btn"
          ghost
          @click="handleRefreshImageType(scope)"
        >
        更新固件编号
        </a-button>
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:region:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除区域?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <region-form-modal ref="regionFormModal" />
  </div>
</template>

<script>
import RegionFormModal from './components/RegionFormModal';

export default {
  name: 'RegionManagement',
  components: { RegionFormModal },
  activated() {
    this.listRegions(this.pageInfo);
  },
  data() {
    return {
      regionList: undefined,
      searchKeyword: '',
      filterSelected: this.$constRepository.region.FILTER_OPTION_REGION_NAME,
      filterOptions: [this.$constRepository.region.FILTER_OPTION_REGION_NAME],
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.region.FILTER_OPTION_REGION_NAME:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listRegions() {
      const params = {
        name: this.requestParams.name,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.region.getRegions(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listRegions();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listRegions();
      }
    },
    handleCreator() {
      this.$refs.regionFormModal.showCreateDialog();
    },
    handleEdit(params) {
      this.$refs.regionFormModal.showUpdateDialog(params);
    },
    onDelete(record) {
      this.$apiManager.region.deleteRegions(record.identifier).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    switchChange(row) {
      const rowObj = this.tableDate.find((data) => data.id === row.id);
      if (rowObj.pushStatus) {
        rowObj.pushStatus = rowObj.pushStatus === 1 ? 2 : 1;
      } else {
        rowObj.pushStatus = 1;
      }
      const params = {
        fields: ['pushStatus'],
        data: rowObj,
      };
      this.$apiManager.region.updateRegions(params).then(() => {
        this.$message.success('修改成功');
        this.loadedPageData();
      });
    },
    handleRefreshImageType(row) {
      this.$apiManager.region.refreshImageType(row.identifier).then(() => {
        this.$message.success('更新成功');
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.region-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    // margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1%;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.region-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
</style>
